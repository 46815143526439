/* You can add global styles to this file, and also import other style files */
.logo{
    display: inline-flex;
    width: 10%;
    margin-right: 20px;
}
.coverphoto{
    width: 40% !important;
    margin-top: -5% !important;
}
.coverlogo{
    width: 15%;
    margin-bottom: 8%;
}
.productbox{
    --tw-bg-opacity: 1;
    background: white;
    color: black;
    border: 3px solid #d6ab58;
    padding: 20% !important;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1.2rem;
}
.section-head-logo{
    width: 60%;
}
.section-head-round{
    background: none !important;
    border: 2px solid #d6ab58 !important;
}
.bg-gold{
    background: #d6ab58 !important;
    color:white !important;
}
@media (max-width: 1024px) {
    .coverlogo{
        visibility: hidden;
    }
    #hero{
        z-index: 1;
    background: white;
    }
    .coverphoto{
        width: 100% !important;
    margin-top: -25% !important;
    }
    .pt-32 {
        margin-top: -15rem;
    }
    .mobilediplaynone{
        display: none;
    }
    .email-text{
        font-size: 1.50rem !important;
    }
    .px-12 {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important;
    }
    .h-screen {
        height: 70vh !important;
        margin-top: 15% !important;
    }
    .py-3 {
        padding-top: 1.5rem !important;
        padding-bottom: 0 !important;
    }
    #example-navbar-warning ul li{
        height: 50px;
    border-bottom: 2px solid #d6ab58;
    }
    .mt-48{
        margin-top: 0px !important;
    }
}
.right-10{
    right: 10px;;
}
.detailsTable{
    width: 100%;
    background: white;
    color: black;
    font-size: 12px;
    margin-top: 10px;
}
.detailsTable tr{
}
.detailsTable tr td{
    padding: 5px;
    border: 2px solid #d6ab58;
}
.detailsTable tr td:first-child{
    text-transform: uppercase;
    font-weight: 600;
}